<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['darkCircles'])">
      <single-answer-question
        id="dark-circles"
        title="label.darkCircles"
        :value="darkCircles"
        :options="darkCirclesOptions"
        :image="$options.darkCirclesImage"
        image-alt="dark circles image"
        yes-no
        dysfunction="skin-appearance"
        @input="onFieldChange('darkCircles', $event)"
      />
    </question-with-error-wrap>

    <question-divider
      v-if="isQuestionDividerVisible"
      class="q-mt24 q-mb32 q-mt32-md q-mb40-md"
      title="questionnaire.tellUsMore.title"
    />

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="darkCircleColorsVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['darkCircleColors'])"
      >
        <multi-answer-question
          id="dark-circle-colors"
          title="label.darkCircleColors"
          :value="darkCircleColors"
          :options="darkCircleColorsOptions"
          dysfunction="skin-appearance"
          @input="onFieldChange('darkCircleColors', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import QuestionDivider from '@/modules/questionnaire/components/common/QuestionDivider';
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  flagAiSelectedOption,
  flagAiSelectedArrayOptions
} from '@/modules/questionnaire/api/helpers';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { DARK_CIRCLE_COLORS_OPTIONS } from '@/modules/questionnaire/constants/steps/skinAppearance';

import darkCirclesImage from '@/modules/questionnaire/assets/images/skin-appearance/dark-circles/dark-circles.svg';

export default {
  name: 'DarkCirclesTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionDivider,
    MultiAnswerQuestion,
    SingleAnswerQuestion,
    QuestionWithErrorWrap
  },
  mixins: [stepTemplateMixin],
  darkCirclesImage,
  props: {
    darkCircles: {
      type: String,
      required: true
    },
    darkCirclesAiSelected: {
      type: String,
      default: ''
    },
    darkCircleColors: {
      type: Array,
      default: () => []
    },
    darkCircleColorsVisible: {
      type: Boolean,
      default: false
    },
    darkCircleColorsAiSelected: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    darkCirclesOptions() {
      return flagAiSelectedOption(YES_NO_RESPONSE_OPTIONS, this.darkCirclesAiSelected);
    },
    darkCircleColorsOptions() {
      return flagAiSelectedArrayOptions(
        DARK_CIRCLE_COLORS_OPTIONS,
        this.darkCircleColorsAiSelected
      );
    },
    isQuestionDividerVisible() {
      return this.darkCircleColorsVisible;
    }
  }
};
</script>
